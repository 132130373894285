.Popup-overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
  }
  .Popup-container {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
    width: 350px;
  }

  .Popup-header {
    margin-top: 0;
    text-align: left;
    color: black;
    user-select: none;
  }
  .Popup-close {
    position: absolute;
    top: 14px;
    right: 20px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .Popup-close:hover {
    cursor: pointer;
    color: black;
  }
  .Popup-content {
    max-height: 30%;
    overflow: auto;
  }