.WccStatusesBlock-container {
    text-align: left;
}

.WccStatusesBlock-header {
    margin: 0;
    padding-bottom: 10px;
    text-align: left;
    font-size: 16px;
    user-select: none;
}

.WccStatusesBlock-values-container {
    margin: 0;
    padding-top: 2px;
    padding-left: 2px;
    padding-right: 2px;

    display: grid;
    grid-template-columns: 90px auto;
    grid-template-rows: auto;
    grid-template-areas: 
      "header header";
    align-items: center;
}

.WccStatusesBlock-value {
    margin: 0;
    padding-bottom: 2px;
    text-align: left;
    font-size: 15px;

    justify-self: start;
}

.WccStatusesBlock-spinner-container {
    min-height: 80px;
    margin: 0;

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 
      "header";
    align-items: center;
    justify-items: center;
}

.WccStatusesBlock-fixed-container {
    min-height: 80px;
    margin: 0;
}