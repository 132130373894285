.GeneralButton-container {
    border-radius: 10px;
    width: fit-content;
    height: 50px;
}

.GeneralButton-container-disabled {
    border-radius: 10px;
    width: fit-content;
    height: 50px;
    pointer-events: none;
}

.GeneralButton-text {
    margin: 0px;
    padding-bottom: 14px;
    padding-top: 12px;
    padding-left: 20px;
    padding-right: 20px;
    user-select: none;
}