.SidebarButton-container {
    background-color: #1B385F;
    height: 80px;
    padding:10px;
}

.SidebarButton-container-active {
    background-color: white;
    height: 80px;
    padding:10px;
}

.SidebarButton-text {
    color: white;
    margin:0;
    user-select: none;
}

.SidebarButton-text-active {
    color: black;
    margin:0;
    user-select: none;
}

.SidebarButton-logo-container {
    fill: white;
}

.SidebarButton-logo-container-active {
    fill: black;
}