.UploadDialog-content {
  margin: 0;
}

.UploadDialog-footer {
    margin: 0;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 
      "header";
    justify-content: center; 
    align-content: center;
}

.UploadDialog-button-to-spinner {
  min-height: 80px;
}

.UploadDialog-fileinput {
  max-width: 282px;
  padding-top: 2px;
  display: grid;
  align-self: center;
}