.LoadingSpinner-container {
    width: 80px;
    height: 80px;
    margin: 0;
}

.LoadingSpinner-body {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 40px;
    height: 60px;
    background-color: #1B385F;
    border-radius: 10px;
    animation: identifier 1s linear infinite;
}

@keyframes identifier {
    0% {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 40px;
        height: 60px;
    }
    25% {
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
        width: 50px;
        height: 50px;
    }
    50% {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 60px;
        height: 40px;
    }
}

.LoadingSpinner-container-small {
    width: 40px;
    height: 40px;
    margin: 0;
}

.LoadingSpinner-body-small {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 20px;
    height: 30px;
    background-color: #1B385F;
    border-radius: 5px;
    animation: identifier-small 0.5s linear infinite;
}

@keyframes identifier-small {
    0% {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 20px;
        height: 30px;
    }
    25% {
        margin-left: 8px;
        margin-right: 8px;
        margin-top: 8px;
        margin-bottom: 8px;
        width: 25px;
        height: 25px;
    }
    50% {
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 30px;
        height: 20px;
    }
}