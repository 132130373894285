.Toolbar-container {
    background-color: #1B385F;
    height:50px;

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 
      "header header header";
    justify-content: end; 
}

.Toolbar-sign-container {
  align-self: center;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
}
.Toolbar-sign-out-text {
  margin: 0;
  align-self: center;
  color: white;
  font-size: 17px;
  padding-right: 10px;
  padding-top: 3px;
  user-select: none;
}
.Toolbar-sign-icon {
  align-self: center;
  max-width: 26px;
  color: white
}
.Toolbar-sign-icon:hover {
  align-self: center;
  max-width: 26px;
  color: #CFCFCF
}

.Toolbar-sign-in-text {
  margin: 0;
  align-self: center;
  color: white;
  font-size: 17px;
  padding-right: 10px;
  padding-top: 2px;
  user-select: none;
}