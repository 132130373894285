.TextInput-container {
    text-align: left;
}

.TextInput-header {
    margin: 0;
    padding-bottom: 2px;
    text-align: left;
    font-size: 16px;
    user-select: none;
}

.TextInput-error {
    margin: 0;
    padding-top: 2px;
    text-align: left;
    min-height: 25px;
    font-size: 15px;
}

.TextInput-error-grey {
    color: grey;
}

.TextInput-error-red {
    color: red;
}