.ArtifactList-tab {
    background-color: white;
    margin: 0;
    justify-self: center;
}

.ArtifactList-container {
    background-color: white;
    margin: 0;
}

.ArtifactList-container-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    height: 80px;
}

.ArtifactList-list-container {
    background-color: white;
    margin: 5px;
}

.Artifact-list-container-toolbar {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #E8E8E8;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    display: grid;
    grid-template-columns: 500px 150px;
    grid-template-rows: auto;
    grid-template-areas: 
      "header header"
}

.Artifact-list-container-toolbar-item {
    justify-self: start;
    user-select: none;
}

.ArtifactList-item-separator {
    margin: 0;
}