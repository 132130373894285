.CheckWccBlock-container {
    margin: 0;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
}

.CheckWccBlock-info-text {
    user-select: none;
    margin: 0;
}

.CheckWccBlock-result-text {
  margin: 0;
}

.CheckWccBlock-footer {
    margin: 0;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 
      "header";
    justify-content: center; 
    align-content: center;
}

.CheckWccBlock-button-to-spinner {
  min-height: 80px;
}