.TextForm-container {
    text-align: left;
}

.TextForm-header {
    margin: 0;
    padding-bottom: 10px;
    text-align: left;
    font-size: 16px;
    user-select: none;
}

.TextForm-value {
    margin: 0;
    padding-bottom: 2px;
    text-align: left;
    font-size: 16px;
    word-break: break-all
}

.TextForm-value-container {
    min-height: 52px;
    margin: 0;
    padding-top: 2px;
}
