.ArtifactListItem-container {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0px;

    display: grid;
    grid-template-columns: 500px 150px;
    grid-template-rows: auto;
    grid-template-areas: 
      "header header";
}

.ArtifactListItem-container:hover {
    background-color: #E8E8E8;
}

.ArtifactListItem-element {
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    justify-self: start;
    user-select: none;
}