.UploadResultBlock-container {
    border-radius: 10px;
    border: 1px solid black;
    margin: 4px;

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 
      "header";
}

.UploadResultBlock-container-success {
    border: 1px solid green;
}

.UploadResultBlock-container-error {
    border: 1px solid red;
}

.UploadResultBlock-header {
    margin: 0;
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    justify-self: start;
    user-select: none;
}

.UploadResultBlock-header-success {
    color: green;
}

.UploadResultBlock-header-error {
    color: red;
}

.UploadResultBlock-element {
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    justify-self: start;
}