.ContrastButton-container {
    background-color: #1B385F;
}

.ContrastButton-container:hover {
    background-color:  #234470;
}

.ContrastButton-container-disabled {
    background-color: #6a7c94;
}

.ContrastButton-text {
    color: white;
}