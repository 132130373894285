.ArtifactListToolbar-container {
    background-color: white;
    margin: 0;
    height: 86px;
    padding-left: 5px;
    padding-right: 5px;

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 
      "header header"
}

.ArtifactListToolbar-item-1 {
    justify-self: start;
    align-self: center;
}

.ArtifactListToolbar-item-2 {
    justify-self: end;
    align-self: center;
}

.ArtifactListToolbar-search-hint {
    font-size: 13px;
    margin: 0;
}